<template>
  <v-container class="text-center">
    <v-row
      justify="space-between"
      class="mx-0 mb-1"
    >
      <h3>Stocks:</h3>
    </v-row>
    <v-row
      class="mx-0"
    >
      <v-list
        width="100%"
      >
        <v-list-item>
          <v-col
              class="pa-0"
          >
              <v-row class="ma-0">
                  <v-col>
                      <h3>
                        Symbol
                      </h3>
                  </v-col>
                  <v-col>
                    <h3>
                      Name
                    </h3>
                  </v-col>
              </v-row>
          </v-col>
          <v-col
              cols="1"
          >
          </v-col>
        </v-list-item>
        <v-list-item
              v-for="ticker in tickerList"
              :key="ticker.id"
              class="mb-1"
          >
          <v-col
              :to="{name:'overview', params: {ticker: ticker.id}}"
              class="pa-0"
          >
              <v-row class="ma-0">
                  <v-col>
                      <div>
                        {{ ticker.symbol }}
                      </div>
                  </v-col>
                  <v-col>
                    <div>
                      {{ ticker.name }}
                    </div>
                  </v-col>
              </v-row>
          </v-col>
          <v-col
              cols="1"
          >
              <v-btn @click="popDialog(ticker.id)" v-if="!ticker.disable_icon">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
          </v-col>
        </v-list-item>
      </v-list>
    </v-row>
    <v-dialog
      v-model="dialog.active"
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>
          Watchlists:
        </v-card-title>
        <v-col>
          <v-checkbox
            v-for="watchlist in watchLists"
            :key="watchlist.id"
            :value="watchlist.id"
            :label="watchlist.name"
            v-model="watchlist.selected"
            @change="updateWatchList({...watchlist})"
            class="mt-0"
          >

          </v-checkbox>
        </v-col>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Overview',
  data: () => ({
    dialog: {
      active: false,
      ticker: ''
    }
  }),
  computed: {
    tickerList () {
      return this.$store.getters.tickerList
    },
    watchLists () {
      const watch = this.$store.getters.user.watchLists?.map(
        watchlist => ({
          ...watchlist,
          selected: (watchlist.tickers.filter(ticker => ticker.id === this.dialog.ticker)?.length !== 0) ? watchlist.id : ''
        })
      )
      return ((watch?.length ?? 0) > 0) ? [...watch] : []
    }
  },
  mounted () {
    this.$store.dispatch('updateTickerList')
  },
  methods: {
    popDialog (tickerId) {
      this.dialog.ticker = tickerId
      this.dialog.active = !this.dialog.active
    },
    updateWatchList ({ id, selected }) {
      if (selected) {
        this.$store.dispatch('addTickerToWatchList', { watchListId: id, tickerId: this.dialog.ticker })
      } else {
        this.$store.dispatch('deleteTickerFromWatchList', { watchListId: id, tickerId: this.dialog.ticker })
      }
    }
  }
}
</script>
